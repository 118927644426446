<template>
  <div class="ParkingSpaceNew">
  <ParkingSpaceNewxg v-if="type=='xg'"></ParkingSpaceNewxg >
  <ParkingSpaceNewjp v-else></ParkingSpaceNewjp>
  </div>
</template>
  
  <script>
import ParkingSpaceNewjp from "./ParkingSpaceNewjp.vue";
import ParkingSpaceNewxg from "./ParkingSpaceNewxg.vue";

export default {
  name: "ParkingSpaceNew",
  data() {
    return {
     type:''
    };
  },
  components: {
    ParkingSpaceNewjp,
    ParkingSpaceNewxg
  },
  watch: {

  },
  created() {
  this.type=  JSON.parse(sessionStorage.getItem("userInfo")).business_id==549? 'xg':'jp'

  },
  mounted() {

  },
  methods: {
  
  },
};
</script>
  
  <style lang="scss" >
.ParkingSpaceNew {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
  