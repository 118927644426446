var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ParkingSpaceNewxg" },
    [
      _c("div", { staticClass: "cnt-body" }, [
        _c("div", { staticClass: "ParkingSpace-title" }, [
          _c("div", { staticClass: "ParkingSpace-title-left" }, [
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "请选择城市仓",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeCity()
                      },
                    },
                    model: {
                      value: _vm.city,
                      callback: function ($$v) {
                        _vm.city = $$v
                      },
                      expression: "city",
                    },
                  },
                  _vm._l(_vm.cityStoreList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "请选择自提点",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeCity()
                      },
                    },
                    model: {
                      value: _vm.self,
                      callback: function ($$v) {
                        _vm.self = $$v
                      },
                      expression: "self",
                    },
                  },
                  _vm._l(_vm.selfTakeList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _vm._v(" 供货日期： "),
                _c(
                  "div",
                  { staticClass: "inputs" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        clearable: false,
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择供货日期",
                      },
                      on: { change: _vm.timeChange },
                      model: {
                        value: _vm.datetime,
                        callback: function ($$v) {
                          _vm.datetime = $$v
                        },
                        expression: "datetime",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "ParkingSpace-title-right" },
            [
              _c("el-button", {
                staticStyle: {
                  background: "#333333",
                  "border-color": "#333333",
                },
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.onHandleRefresh },
              }),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.clear } },
                [_vm._v("全部清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.disabledExport },
                  on: { click: _vm.exportHandle },
                },
                [_vm._v("导出文件")]
              ),
              _c(
                "div",
                { staticStyle: { "margin-left": "10px" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: `${_vm.BASE.PRO2}/vehicleLayout/list/import`,
                        headers: _vm.headers,
                        data: { date: _vm.datetime },
                        "on-success": _vm.handlePreview,
                        "on-error": _vm.handleRemove,
                        "auto-upload": true,
                        "show-file-list": false,
                        accept: ".xlsx,.xls",
                        multiple: false,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "trigger", type: "primary" },
                          slot: "trigger",
                        },
                        [_vm._v("导入")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "ParkingSpace-cnt" },
          _vm._l(_vm.datalist, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                class:
                  item.name == "F区"
                    ? "ParkingSpace-cnt-itemf ParkingSpace-cnt-item"
                    : "ParkingSpace-cnt-item",
              },
              [
                _c("div", { staticClass: "item-top" }, [
                  _c("div", { staticClass: "item-top-title" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "item-top-wen" }, [
                    _c("i", {
                      staticClass: "el-icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.clear(item)
                        },
                      },
                    }),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clear(item)
                          },
                        },
                      },
                      [_vm._v("清除")]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "item-cnt1" },
                  _vm._l(item.child, function (itemchild, index1) {
                    return _c(
                      "div",
                      { key: index1 + "s", staticClass: "item-cnt-input" },
                      [
                        _c(
                          "div",
                          {
                            class:
                              _vm.actives == itemchild.label
                                ? "popover-item popover-item-bg"
                                : _vm.showcss == itemchild.label
                                ? "popover-item popover-item-boder"
                                : "popover-item",
                            on: {
                              click: function ($event) {
                                return _vm.clcikpopover(
                                  itemchild,
                                  index,
                                  index1
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "popover-item-title" }, [
                              _vm._v(" " + _vm._s(itemchild.label) + " "),
                            ]),
                            _c("div", { staticClass: "popover-item-line" }),
                            _c(
                              "div",
                              { staticClass: "popover-item-cnt" },
                              [
                                itemchild.data.length > 0
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.htmlcnt1(itemchild.data),
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { color: "#000" },
                                            attrs: { type: "text" },
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "htmlcss",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.htmlcnt(itemchild.data)
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.centerDialogVisible,
            width: "60%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
            close: function ($event) {
              _vm.inputcnt = ""
            },
          },
        },
        [
          _vm.indexs !== ""
            ? _c("div", { staticClass: "popover-click-item" }, [
                _c(
                  "div",
                  { staticClass: "popover-click-top" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入内容" },
                      on: { input: _vm.inputchange },
                      model: {
                        value: _vm.inputcnt,
                        callback: function ($$v) {
                          _vm.inputcnt = $$v
                        },
                        expression: "inputcnt",
                      },
                    }),
                  ],
                  1
                ),
                _vm.datalist[_vm.indexs].child[_vm.indexs1].data.length > 0
                  ? _c(
                      "div",
                      { staticClass: "popover-click-tag" },
                      _vm._l(
                        _vm.datalist[_vm.indexs].child[_vm.indexs1].data,
                        function (items, i) {
                          return _c(
                            "div",
                            {
                              key: `s${i}`,
                              staticStyle: { "margin-bottom": "10px" },
                            },
                            [
                              _c(
                                "el-tag",
                                {
                                  staticStyle: { "margin-right": "20px" },
                                  attrs: { closable: "", type: "warning" },
                                  on: {
                                    close: function ($event) {
                                      return _vm.onHandleClose(
                                        _vm.datalist[_vm.indexs].child[
                                          _vm.indexs1
                                        ],
                                        i
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        items.type == 1 ? "城市仓" : "自提点"
                                      ) +
                                      _vm._s(items.name) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "popover-click-radio" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "1" },
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [_vm._v("城市仓")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "2" },
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [_vm._v("自提点")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "popover-click-table" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: {
                          data: _vm.inputcnt
                            ? _vm.inputtable
                            : _vm.popoverdatalist,
                          height: "100%",
                          "row-class-name": _vm.tableRowClassName,
                        },
                        on: {
                          "cell-click": function ($event) {
                            return _vm.onHandleCellClick(
                              $event,
                              _vm.datalist[_vm.indexs].child[_vm.indexs1]
                            )
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { property: "name", label: "名称" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.radio == 1
                                              ? "【城市仓】"
                                              : "【自提点】"
                                          ) +
                                          _vm._s(scope.row.name) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3296252233
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { property: "name" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { color: "red" } },
                                      _vm._l(scope.row.bind, function (item) {
                                        return _c("div", { key: item.id }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.fontsizecnt(item)) +
                                              " "
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3828332996
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { width: "30" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._l(
                                        _vm.itemchildtag,
                                        function (es, i) {
                                          return _c("div", { key: `tag${i}` }, [
                                            es.model_id == scope.row.id &&
                                            _vm.radio == es.type
                                              ? _c("div", [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-circle-check",
                                                    staticStyle: {
                                                      color: "#b4842f",
                                                    },
                                                  }),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            337010980
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closedialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.popoverhide(
                        _vm.datalist[_vm.indexs].child[_vm.indexs1]
                      )
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }